import './About.css';
import aboutImage from '../images/About.png';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const {t} = useTranslation();

    return(
        <div className='about-container'>
            <div className='left'>
                <h2 id="about">{t("header1")}</h2>
                <p>
                    {t("description")}
                </p>
            </div>
            <div className='right'>
                <img src={aboutImage}/>
            </div>
        </div>
    );
}

export default Navbar;
