import React from 'react';
import "./Footer.css";

function Footer() {
    return (
        <div className='footer-container'>
            <span></span>
            <p>Created by Jakub Zacher</p>
        </div>
    )
}

export default Footer
