import './Skills.css';
import htmlIcon from "../images/icons8-html-5-96.png";
import cssIcon from "../images/icons8-css3-96.png";
import jsIcon from "../images/icons8-javascript-96.png";
import reactIcon from "../images/icons8-react-a-javascript-library-for-building-user-interfaces-96.png"
import gitIcon from "../images/icons8-git-96.png";
import githubIcon from "../images/icons8-github-96.png";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

function Navbar() {
    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.2,
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    }

    const skillElement = (image, title) => {
        return (
            <motion.div className='skill-element' variants={item}>
                <img src={image} />
                <div className='title'>{title}</div>
            </motion.div>
        );
    }

    const {t} = useTranslation();

    return (
        <div className='skills-container'>
            <h2 id="skills">{t("header2")}</h2>
            <motion.div className='skills-elements'
                variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                {skillElement(htmlIcon, "HTML")}
                {skillElement(cssIcon, "CSS")}
                {skillElement(jsIcon, "JavaScript")}
                {skillElement(reactIcon, "ReactJS")}
                {skillElement(gitIcon, "git")}
                {skillElement(githubIcon, "Github")}
            </motion.div>
        </div>
    );
}

export default Navbar;
