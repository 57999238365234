import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug:true,
    fallbackLng: "en",
    resources: {
        en: {
            translation: {
                navbarLink1: "Home",
                navbarLink2: "About",
                navbarLink3: "Skills",
                navbarLink4: "Projects",
                navbarLink5: "Contact",
                header1: "About me",
                header2: "Skills",
                header3: "My projects",
                header4: "Contact",
                greeting: "Hello, my name is",
                description: `I am 24 years old and a junior frontend developer. My hobby is creating by coding :). I am an engineering graduate from the Tarnów Academy, 
                    majoring in computer science. I learn and develop my skills in designing applications and websites in various programming languages and technologies,
                    such as HTML, CSS, JavaScript, ReactJS, Git, Github. To my credit, I have many completed projects that I am very proud of.`,
                formInfo1: "Subject",
                formInfo2: "Message",
                buttonSend: "Send",
            }
        },
        pl: {
            translation: {
                navbarLink1: "Strona główna",
                navbarLink2: "O mnie",
                navbarLink3: "Umiejętności",
                navbarLink4: "Projekty",
                navbarLink5: "Kontakt",
                header1: "O mnie",
                header2: "Umiejętności",
                header3: "Moje projekty",
                header4: "Kontakt",
                greeting: "Cześć, nazywam się",
                description: `Mam 24 lata i jestem junior frontend developerem. Moją pasją jest tworzenie poprzez kodowanie :). Jestem absolwentem studiów inżynierskich na kierunku Informatyka 
                    Akademii Tarnowksiej. Uczę się i rozwijam swoje umiejętności w projektowaniu aplikacji i stron internetowych
                    w różnych językach programowania i technologiach, takich jak HTML, CSS, JavaScript, ReactJS, Git, Github.
                    Za sobą mam wiele ukończonych projektów, z których jestem bardzo dumny.`,
                formInfo1: "Tytuł",
                formInfo2: "Wiadomość",
                buttonSend: "Wyślij",
            }
        }
    }
})
