import './Navbar.css';
import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import LanguageSelector from './languageSelector';

function Navbar() {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    let menuRef = useRef();

    useEffect(() => {
        const handler = (event) => {
            if(!menuRef.current.contains(event.target)){
                setIsOpen(false);
            }  
        };

        document.addEventListener("mousedown", handler);

        return() => {
            document.removeEventListener("mousedown", handler);
        }
    });

    return(
        <div className='navbar-container' ref={menuRef}>
            <div className={`links ${isOpen ? "open" : ""}`}>
                <Link to="#home" onClick={toggleMenu}>{t("navbarLink1")}</Link>
                <Link to="#about" onClick={toggleMenu}>{t("navbarLink2")}</Link>
                <Link to="#skills" onClick={toggleMenu}>{t("navbarLink3")}</Link>
                <Link to="#projects" onClick={toggleMenu}>{t("navbarLink4")}</Link>
                <Link to="#contact" onClick={toggleMenu}>{t("navbarLink5")}</Link>
                <LanguageSelector/>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <i class={`fa-solid ${isOpen ? "fa-close" : "fa-bars"} `}></i>
            </div>
        </div>
    );
}

export default Navbar;
