import React from 'react'
import './Contact.css'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t } = useTranslation();

    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "0240b20b-5c0c-433d-9fa2-9cd1dce7bce3");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <div className='contact-container' id="contact">
            <h2>{t("header4")}</h2>
            <motion.div className='contact-form' initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.1 }} viewport={{ once: true }}>
                <form className='form' onSubmit={onSubmit}>
                    <input type="email" name="email" placeholder='Email' required />
                    <input type="text" name="_subject" placeholder={t("formInfo1")} />
                    <textarea type="text" name="name" placeholder={t("formInfo2")} required />
                    <button type="submit">{t("buttonSend")} <i class="fa-solid fa-paper-plane"></i></button>
                </form>
                <span>{result}</span>
            </motion.div>
            <div className='socials-container'>
                <a href="https://www.youtube.com/channel/UCloqtzghbxq7yGOCiyZSEIw" target='_blank'><i class="fa-brands fa-youtube"></i></a>
                <a href="https://twitter.com/Jackoberek" target='_blank'><i class="fa-brands fa-x-twitter"></i></a>
                <a href="https://github.com/Suivez" target='_blank'><i class="fa-brands fa-github"></i></a>
                <a href="https://www.linkedin.com/in/jakub-zacher-2a3019248/" target='_blank'><i class="fa-brands fa-linkedin"></i></a>
            </div>
        </div>
    )
}

export default Contact
