import './Projects.css';
import adviceGenerator from "../images/advice_generator.png";
import huddlePage from "../images/huddle_page.png";
import notificationsPage from "../images/notifications_page.png";
import newsletterSignUp from "../images/nwesletter_sign_up.png";
import ratingComponent from "../images/rating_component.png";
import signUpComponent from "../images/sign_up_component.png";
import sunnysidePage from "../images/sunnyside_page.png";
import ageCalculator from "../images/age_calculator.png";
import tipCalculator from "../images/tip_calculator.png";
import interactiveCard from "../images/interactive_card.jpg";
import mortgageCalculator from "../images/mortgage_calculator.jpg";
import faqAccordion from '../images/faq-accordion.jpg';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

function Projects() {
    const showAnimation = {
        hidden: { opacity: 0, scale: 0.5 },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }
        }
    }

    const projectElement = (image, title, linkWeb, linkGithub) => {
        return (
            <motion.div
                variants={showAnimation}
                initial='hidden'
                whileInView="show"
                viewport={{ once: true }}
            >
                <motion.div className='project-element'
                    whileHover={{ scale: 1.1 }}
                >
                    <a href={linkWeb} target='_blank'>
                        <img src={image} alt='project'></img>
                        <div className='description'>
                            <h3>{title}</h3>
                            <object>
                                <a href={linkGithub} target='_blank'>
                                    <i class="fa-brands fa-github"></i>
                                </a>
                            </object>
                        </div>
                    </a>
                </motion.div>
            </motion.div>
        );
    }

    const {t} = useTranslation();

    return (
        <div className='projects-container' id="projects">
            <h2>{t("header3")}</h2>
            <div className='projects-elements'>
                {projectElement(ageCalculator, "Age calculator", "https://age-calculator-app-alpha.vercel.app", "https://github.com/Suivez/Age-calculator-app")}
                {projectElement(tipCalculator, "Tip calculator", "https://tip-calculator-app-steel.vercel.app", "https://github.com/Suivez/Tip-calculator-app")}
                {projectElement(newsletterSignUp, "Newsletter sign up form", "https://newsletter-sign-up-form-with-success-message-neon.vercel.app", "https://github.com/Suivez/Newsletter-sign-up-form-with-success-message")}
                {projectElement(ratingComponent, "Interactive rating component", "https://interactive-rating-component-suivez.vercel.app", "https://github.com/Suivez/Interactive-rating-component")}
                {projectElement(sunnysidePage, "Sunnyside agency page", "https://sunnyside-agency-landing-page-rho.vercel.app", "https://github.com/Suivez/Sunnyside-agency-landing-page")}
                {projectElement(huddlePage, "Huddle landing page", "https://huddle-landing-page-with-curved-sections-wine.vercel.app", "https://github.com/Suivez/Huddle-landing-page-with-curved-sections")}
                {projectElement(notificationsPage, "Notifications page", "https://notifications-page-main-iota.vercel.app", "https://github.com/Suivez/Notifications-page-main")}
                {projectElement(adviceGenerator, "Advice generator", "https://advice-generator-app-suivez.vercel.app", "https://github.com/Suivez/Advice-generator-app")}
                {projectElement(signUpComponent, "Sign up form", "https://intro-component-with-sign-up-form-suivez.vercel.app", "https://github.com/Suivez/Intro-component-with-sign-up-form")}
                {projectElement(mortgageCalculator, "Mortgage repayment calculator", "https://mortgage-repayment-calculator-main-eta.vercel.app", "https://github.com/Suivez/Mortgage-repayment-calculator-main")}
                {projectElement(interactiveCard, "Interactive card details", "https://interactive-card-details-form-main-tawny.vercel.app", "https://github.com/Suivez/Interactive-card-details-form-main")}
                {projectElement(faqAccordion, "FAQ accordion", "https://faq-accordion-gamma-lyart.vercel.app", "https://github.com/Suivez/Faq-accordion")}
            </div>
        </div>
    );
}

export default Projects;
