import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ScrollToAnchor from './components/ScrollToAnchor';
import { BrowserRouter} from 'react-router-dom';

function App() {
  return(
    <div className='App'>
      <BrowserRouter>
        <Navbar/>
        <ScrollToAnchor/>
          <Home/>
          <About/>
          <Skills/>
          <Projects/>
          <Contact/>
          <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
