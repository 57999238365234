import './Home.css';
import { delay, motion, useAnimation, useInView } from "framer-motion";
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const {t} = useTranslation();

    return (
        <motion.div className='home-container' id="home"
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
            <motion.h2
                variants={{
                    hidden: { opacity: 0, y: -80 },
                    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } }
                }}>
                {t("greeting")}
            </motion.h2>
            <motion.h1
                variants={{
                    hidden: { opacity: 0, y: 80 },
                    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.7 } }
                }}
            >
                Jakub Zacher
            </motion.h1>
        </motion.div>
    );
}

export default Navbar;
